@import "/src/assets/styles/abstracts/mixins";

.add-edit-learning-path-related-item-modal {
  @include flex(column, flex-start, flex-start);
  row-gap: 2rem;
}

.add-edit-learning-path-related-item-modal__textarea {
  min-height: 5rem;
  max-height: 5rem;
  resize: none;
}

.add-edit-learning-path-related-item-modal__label {
  background-color: var(--card-color);
}

.add-edit-learning-path-related-item-modal__button {
  align-self: flex-end;
}