.auth-form {
  @include flex(column, center, flex-start);
}
.auth-form__title {
  margin-bottom: 1rem;
  @include font(2rem, 500, var(--primary-text-color));
}
.auth-form__inputs {
  width: 100%;
  margin-bottom: 1rem;
}
.auth-from__verification-message,
.auth-form__forgot-password-message {
  margin-bottom: .75rem;
  @include font(1rem, 400, var(--primary-text-color), center);
}
.auth-from__verification-email,
.auth-form__forgot-password-email {
  @include font(1rem, 500, var(--primary-light));
}
.auth-form__inputs {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
.auth-form__input-label {
  background-color: var(--background-color);
}
.auth-from__controls {
  width: 100%;
  @include flex(column, flex-start, flex-start);
}
.auth-from__controls-main {
  @include flex(row, center, flex-start);
}
.auth-form__btn {
  margin-right: 1rem;
}
.auth-form__link {
  text-decoration: underline;
  color: var(--primary-text-color);
  @include font(1rem, 500, var(--primary-text-color));


  &--small {
    font-size: 0.875rem;
  }
}
.auth-from__forgot {
  margin-top: 1.25rem;
  @include font(1rem, 500, var(--primary-text-color));
}
.auth-form__verification-container {
  @include flex(column, center, center);
}
.auth-form__verification-text {
  @include font(1rem, 500, var(--primary-text-color), center);
  margin-bottom: 1rem;

  &--error {
    color: var(--danger);
  }
}