@import "/src/assets/styles/abstracts/mixins";


.auth-layout {
  min-height: 100vh;
  padding: 0 1rem;
  @include flex(column, center, center);
}
.auth-layout__container {
  max-width: 26.25rem;
  width: 100%;
  border-radius: 0.375rem;
  padding: 1.5rem;
  @include border(all, var(--auth-container-border-color));
  box-shadow: 0 0 .313rem var(--shadow-md-color);
}