:root {
  // Main colors
  --primary: #313C74;
  --primary-dark: #182257;
  --primary-light: #525C91;
  --primary-alpha: rgba(49, 60, 116, .6);

  // Entiry colors
  --danger: #E11717;
  --danger-dark: #CB1D1D;
  --danger-alpha: rgba(225, 23, 23, .6);

  // Shadow colors
  --shadow-sm-color: rgba(0, 0, 0, 0.1);
  --shadow-md-color: rgba(0, 0, 0, 0.2);
  --shadow-lg-color: rgba(0, 0, 0, 0.3);

  // Text
  --primary-text-color: #081E3B;
  --secondary-text-color: #9fa9be;

  // Background colors
  --background-color: #F8F8F8;
  --header-footer-aside-background-color: #ffffff;
  --white-background-hover: rgba(0, 0, 0, .03);
  --white-background-active: rgba(0, 0, 0, .045);
  --white-background-highlited: rgba(0, 0, 0, .055);
  --white-background-highlited-hover: rgba(0, 0, 0, .065);
  --white-background-highlited-active: rgba(0, 0, 0, .08);

  // Border colors
  --auth-container-border-color: var(--primary);
  --input-border-color: #A2ACC0;
  --input-border-focus-color: #1665D8;
  --separator-border-color: rgba(0, 0, 0, .12);
  --learning-path-item-dot-border-color: var(--primary-alpha);

  // Natural colors
  --white: #FFFFFF;
  --gray: #c8c8c8;
  --gray-100:#76767619;

  // Natural colors (swap)
  --white-swap: #FFFFFF;
  --black-swap: #000000;

  // Components colors
  --card-color: #ffffff;
}

.App.dark {
  // Text
  --primary-text-color: #F0F0F0;
  --secondary-text-color: #bebebe;

  // Shadow colors
  --shadow-sm-color: rgba(150, 150, 150, .1);
  --shadow-md-color: rgba(150, 150, 150, .2);
  --shadow-lg-color: rgba(150, 150, 150, .3);

  // Background colors
  --background-color: #121212;
  --header-footer-aside-background-color: #202020;
  --white-background-hover: rgba(255, 255, 255, .03);
  --white-background-active: rgba(255, 255, 255, .045);
  --white-background-highlited: rgba(255, 255, 255, .055);
  --white-background-highlited-hover: rgba(255, 255, 255, .065);
  --white-background-highlited-active: rgba(255, 255, 255, .08);

  // Border colors
  --input-border-color: #F0F0F0;
  --input-border-focus-color: #478dfe;
  --separator-border-color: rgba(255, 255, 255, .2);

  // Natural colors
  --gray: #767676;
  --gray-100: #c8c8c819;

  // Natural colors (swap)
  --white-swap: #000000;
  --black-swap: #FFFFFF;

  // Components colors
  --card-color: #232323;
}

// --primary-darker: #070F3A;
// --primary-lighter: #7C84AE;


// --primary: #313C74;
// --primary-dark: #182257;
// --primary-light: #525C91;