.base-link-button {
  background-color: transparent;
  border: none;
  color: var(--primary-text-color);
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color .2s ease-in-out;
  width: 100%;
  @include flex(row, center, flex-start);

  &:hover {
    background-color: var(--white-background-highlited-hover);
    cursor: pointer;
  }

  &:active {
    background-color: var(--white-background-highlited-active);
    cursor: pointer;
  }

  &--rounded {
    padding: 0.7rem 0.9rem;
    border-radius: 1.5rem;
  }

  &--circle {
    border-radius: 50%;
  }

  &--primary {
    color: var(--primary);
  }

  &--danger {
    color: var(--danger);
  }
}

.base-link-button__icon {
  margin-right: 0.5rem;
}

.base-link-button__text {
  @include font(1rem, 500, inherit);
}
