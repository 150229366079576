@import "/src/assets/styles/abstracts/mixins";

.profile-avatar {
  @include flex(row, flex-start, space-between);
}

.profile-avatar__actions {
  @include flex(column, flex-start, flex-start);
  row-gap: 1.5rem;
}

.profile-avatar__upload-label {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.profile-avatar__upload-input {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

.profile-avatar__image-wrapper {
  width: 12.5rem;
  height: 12.5rem;
}

.profile-avatar__image {
  width: 100%;
  height: 100%;
  display: block;
}