@import "/src/assets/styles/abstracts/mixins";

.nav-bar {
  width: 100%;
  padding: 2rem 0;
  max-height: calc(100dvh - 5.625rem);
  overflow-y: auto;
  @include flex(column, flex-start, flex-start);
}
.nav-bar__add-button-wrapper {
  margin: 0 auto;
}
.nav-bar__links {
  width: 100%;
  margin-top: 2rem;
}