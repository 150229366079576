@import "src/assets/styles/abstracts/mixins";

// TODO move all colors to variables
.settings-theme__preview-wrapper {
  width: 100%;
  height: 8.125rem;
  @include flex(row, stretch, flex-start);
}

.settings-theme__preview {
  width: 100%;
  height: 100%;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;

  &--dark {
    background-color: #121212;

    .settings-theme__preview-text {
      background-color: #f0f0f0;
    }
  }

  &--light {
    background-color: #f8f8f8;

    .settings-theme__preview-text {
      background-color: #081e3b;
    }
  }

  &--half {
    width: 50%;

    &:nth-child(1) {
      border-top-right-radius: 0;
    }

    &:nth-child(2) {
      border-top-left-radius: 0;
    }
  }
}

.settings-theme__preview-text {
  margin: 1rem 0 0 1rem;
  height: .5rem;
  border-radius: 100rem;
  width: 30%;

  &:nth-child(1) {
    width: 47%;
  }

  &:nth-child(2) {
    width: 60%;
  }

  &:nth-child(3) {
    width: 30%;
  }
}