.avatar {
  @include flex(row, center, center);

  &--sm {
    width: 2rem;
    height: 2rem;
  }

  &--md {
    width: 3rem;
    height: 3rem;
  }

  &--lg {
    width: 4rem;
    height: 4rem;
  }
}
.avatar__image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 50%;
}
.avatar__svg {
  width: 90%;
  height: 90%;
  display: block;
  fill: var(--primary-text-color);
}