.loader-wrapper {
  &--small {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--normal {
    width: 2.5rem;
    height: 2.5rem;
  }

  &--large {
    width: 4rem;
    height: 4rem;
  }
}

.loader {
  display: block;
  color: currentColor;
  animation: rotate 1.3s linear infinite;
}

.loader-circle {
  stroke: currentColor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  animation: rotate-circle 1.4s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
     transform: rotate(360deg);
   }
}

@keyframes rotate-circle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
     stroke-dasharray: 100px, 200px;
     stroke-dashoffset: -125px;
   }
}