@import "/src/assets/styles/abstracts/mixins";

.learning-paths {
  width: 100%;
  @include flex(row, stretch, flex-start); // TODO change with grid
  flex-wrap: wrap;
  gap: 1rem;
}

.learning-paths__content {
  width: 100%;
  @include flex(row, stretch, flex-start); // TODO change with grid
  flex-wrap: wrap;
  gap: 1rem;
}