@import "/src/assets/styles/abstracts/mixins";

.learning-path-item {
  width: calc(33.333333333% - 0.666666667rem);
  background-color: var(--card-color);
  border-radius: 0.375rem;
  box-shadow: 0 0 5px var(--shadow-md-color);
  transition: box-shadow .2s ease;
  @include flex(column, flex-start, flex-start);

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 7.5px var(--shadow-lg-color);

    .learning-path-item__actions-bar {
      opacity: 1;
      visibility: visible;
    }
  }
}

.learning-path-item__header {
  outline: none;
  background-color: transparent;
  border: none;
  padding: 1rem 1rem 0;
  width: 100%;
  margin-bottom: 0.1rem;
  @include flex(column, flex-start, flex-start);

  &:hover {
    cursor: pointer;
  }
}

.learning-path-item__title {
  width: 100%;
  @include font(1.125rem, 700, var(--primary-text-color));
}

.learning-path-item__description {
  width: 100%;
  margin-top: 0.5rem;
  @include font(0.875rem, 400, var(--secondary-text-color));
}

.learning-path-item__tags {
  margin-top: 0.875rem;
  padding: 0 1rem;
  @include flex(row, flex-start, flex-start, wrap);
  gap: 0.5rem;

  &:last-child {
    margin-bottom: 1rem;
  }
}

.learning-path-item__tag {
  box-shadow: 0 0 5px var(--shadow-md-color);
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--primary-alpha);
  @include font(0.875rem, 400, var(--primary-text-color));
}

.learning-path-item__actions-bar {
  width: 100%;
  @include flex(row, center, flex-start);
  gap: 1rem;
  margin-top: auto;
  padding: 0 0.5rem;
  min-height: 2.5rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.learning-path-item__action {
  width: 2rem;
  height: 2rem;
  outline: none;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  @include flex(row, center, center);
  transition: background-color 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: var(--white-background-hover);
  }

  &:active {
    background-color: var(--white-background-active);
  }
}

.learning-path-item__action-icon {
  fill: var(--primary-text-color);
}