//.modal {
//  cursor: default;
//}

.modal-overlay {
  display: block;
  position: fixed;
  z-index: $modal-z-index;
  inset: 0;
  background-color: $modal-overlay-bg-color;
  overflow-y: auto;
  animation: modal-overlay-animation .2s ease 1 forwards;
}

.modal-content-wrapper {
  @include flex(row, center, center);
  min-height: 100%;
  width: 100%;
  outline: none;
}

.modal-content {
  position: relative;
  max-width: 43.75rem;
  width: 100%;
  border-radius: 0.375rem;
  padding: 1.5rem;
  min-height: 1rem;
  margin: 2.5rem 0;
  z-index: $modal-z-index;
  background-color: var(--card-color);
  box-shadow: 0 0 5px var(--shadow-md-color);
  animation: modal-content-animation .2s ease 1 forwards;
}

.modal-header {
  width: 100%;
  margin-bottom: 2rem;
  @include flex(row, center, space-between);
}

.modal-header__title {
  width: 80%;
  line-height: 2rem;
  word-break: break-word;
  @include font(2rem, 700, var(--primary-text-color));
}

.modal-header__close {
  background-color: transparent;
  border: none;
  color:  var(--primary-text-color);
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  @include flex(row, center, center);
  transition: background-color .2s ease;

  &:hover {
    background-color: var(--white-background-highlited-hover);
    cursor: pointer;
  }

  &:active {
    background-color: var(--white-background-highlited-active);
  }
}

.modal-header__close-icon {
  width: 1.75rem;
  height: 1.75rem;
}

@keyframes modal-overlay-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-content-animation {
  from {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}