.base-pagination {
  @include flex(row, center, center);
  column-gap: 0.5rem;
  width: 100%;
}

.base-pagination__page {
  @include flex(row, center, center);
  @include font(1.125rem, 500, var(--primary-text-color), center);
  background-color: var(--card-color);
  border-radius: 0.375rem;
  box-shadow: 0 0 5px var(--shadow-md-color);
  user-select: none;
  transition: box-shadow 0.2s ease, background-color 0.2s ease;

  &:not(&--active) {
    &:hover {
      cursor: pointer;
      background-color: var(--primary-light);
    }

    &:active, &:focus {
      background-color: var(--primary-dark);
    }
  }


  &--sm {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--md {
    width: 2.5rem;
    height: 2.5rem;
  }

  &--lg {
    width: 3rem;
    height: 3rem;
  }

  &--active {
    pointer-events: none;
    font-weight: 500;
    background-color: var(--primary);
    box-shadow: 0 0 5px var(--primary);
    color: var(--white);
  }
}