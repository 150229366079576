.confirm-modal {
  @include flex(column, center, center);
}

.confirm-modal__description {
  @include font(1.5rem, 400, var(--primary-text-color), center);
  margin-bottom: 2.5rem;
}

.confirm-modal__actions {
  @include flex(row, center, flex-end);
  align-self: flex-end;
  column-gap: 1rem;
}

.confirm-modal__loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $conform-modal-loader-z-index;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  border-radius: 0.375rem;
  @include flex(row, center, center);
}