@import "/src/assets/styles/abstracts/mixins";

.aside {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
  width: 11.25rem;
  height: 100dvh;
  max-height: 100dvh;
  overflow: hidden;
  background-color: var(--header-footer-aside-background-color);
}
.aside__logo {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 5.688rem;
  border-bottom: 0.063rem solid var(--gray-100);
  border-right: 0.063rem solid var(--gray-100);
  @include flex(row, center, center);
}
.aside__logo-wrapper {
  @include flex(row, center, center);
}
.aside__logo-img {
  width: 3.75rem;
  height: 3.75rem;
  display: block;
}